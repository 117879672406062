$(() => {
    $('.select-single').select2({
        minimumResultsForSearch: -1,
        templateSelection: formatText,
        templateResult: formatText
    })

    function formatText (icon) {
        return $('<span class="flex-icon"><i class="icon ' + $(icon.element).data('icon') + '"></i> ' + icon.text + '</span>');
    }


});

$(() => {
    $('.select-single-2').select2({
        minimumResultsForSearch: -1,
    })

});

$(() => {
    $('.select-single-3').select2({
        minimumResultsForSearch: -1,
    })

});

$(() => {
    $('.select-single-4').select2({
        minimumResultsForSearch: -1,
    })

});



