$(() => {

    Highcharts.chart('charts', {
        chart: {
            type: 'pie',
            width: 170,
            height: 170,
            custom: {},

            events: {
                render() {
                    const chart = this,
                        series = chart.series[0];
                    let customLabel = chart.options.chart.custom.label;

                    if (!customLabel) {
                        customLabel = chart.options.chart.custom.label =
                            chart.renderer.label(

                                '<div class="graphic-title"><strong>$ 50 000</strong></div>'
                            )
                                .css({
                                    color: '#000',
                                    textAnchor: 'middle',
                                    border: '1px dashed #fff'
                                })
                                .add();
                    }

                    const x = series.center[0] + chart.plotLeft,
                        y = series.center[1] + chart.plotTop -
                            (customLabel.attr('height') / 2);

                    customLabel.attr({
                        x,
                        y
                    });
                    // Set font size based on chart diameter
                    customLabel.css({
                        fontSize: `20px`
                    });
                }
            }
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        title: {
            text: '',
            style: {
                display: 'none'
            }
        },

        tooltip: {
            enabled: false
        },

        legend: {
            enabled: false
        },

        zooming:{
            enabled: false
        },

        plotOptions: {
            pie: {
                size: 163,
                borderWidth: 0,
                dataLabels: {
                    enabled: false
                },
                innerSize: '80%',
            },
        },

        series: [{

            colorByPoint: true,
            enableMouseTracking: false,
            showInLegend: false,
            data: [ {
                name: 'Активы' ,
                y: 17000,

            }, {
                name: 'Команда',
                y: 15000,

            }, {
                name: 'Промо',
                y: 28000,

            }]
        }]
    });

});

$(() => {

    Highcharts.chart('line', {

        chart: {
            type: 'line',
            width: 100,
            height: 50,
        },

        title: {

        },

        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },

        subtitle: {

        },

        tooltip: {
            enabled: false
        },

        yAxis: {
            visible: false,
        },

        xAxis: {
            visible: false,
        },

        plotOptions: {

            line: {
                size: 100
            },

            series: {
                marker: {
                    enabled: false
                },
                color: '#e28d44',
                enableMouseTracking: false,
                showInLegend: false,
            }
        },


        legend: {
            enabled: false
        },

        zooming:{
            enabled: false
        },

        series: [{

        },   {
            name: '',
            data: [
                29.9, 56, 90, 75, 60, 176.0, 135.6, 148.5, 216.4, 194.1,
                95.6, 54.4
            ]
        }],

    });

});

$(() => {

    Highcharts.chart('lineRed', {

        chart: {
            type: 'line',
            width: 100,
            height: 50,
        },

        title: {

        },

        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },

        subtitle: {

        },

        tooltip: {
            enabled: false
        },

        yAxis: {
            visible: false,
        },

        xAxis: {
            visible: false,
        },

        plotOptions: {

            line: {
                size: 100
            },

            series: {
                marker: {
                    enabled: false
                },
                color: '#FA2256',
                enableMouseTracking: false,
                showInLegend: false,
            }
        },


        legend: {
            enabled: false
        },

        zooming:{
            enabled: false
        },

        series: [{

        },   {
            name: '',
            data: [
                29.9, 34, 21, 56, 90, 150, 135.6, 148.5, 110, 170,
                95.6, 54.4
            ]
        }],

    });

});

$(() => {

    Highcharts.chart('lineGreen', {

        chart: {
            type: 'line',
            width: 100,
            height: 50,
        },

        title: {

        },

        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },

        subtitle: {

        },

        tooltip: {
            enabled: false
        },

        yAxis: {
            visible: false,
        },

        xAxis: {
            visible: false,
        },

        plotOptions: {

            line: {
                size: 100
            },

            series: {
                marker: {
                    enabled: false
                },
                color: '#11CABE',
                enableMouseTracking: false,
                showInLegend: false,
            }
        },


        legend: {
            enabled: false
        },

        zooming:{
            enabled: false
        },

        series: [{

        },   {
            name: '',
            data: [
                29.9, 30, 40, 75, 60, 24, 11, 148.5, 200, 194.1,
                95.6, 54.4
            ]
        }],

    });

});

$(() => {

    Highcharts.chart('chartsModal', {
        chart: {
            type: 'pie',
            width: 170,
            height: 170,
            custom: {},

            events: {
                render() {
                    const chart = this,
                        series = chart.series[0];
                    let customLabel = chart.options.chart.custom.label;

                    if (!customLabel) {
                        customLabel = chart.options.chart.custom.label =
                            chart.renderer.label(

                                '<div class="graphic-title"><strong>$ 50 000</strong></div>'
                            )
                                .css({
                                    color: '#000',
                                    textAnchor: 'middle',
                                    border: '1px dashed #fff'
                                })
                                .add();
                    }

                    const x = series.center[0] + chart.plotLeft,
                        y = series.center[1] + chart.plotTop -
                            (customLabel.attr('height') / 2);

                    customLabel.attr({
                        x,
                        y
                    });
                    // Set font size based on chart diameter
                    customLabel.css({
                        fontSize: `20px`
                    });
                }
            }
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        title: {
            text: '',
            style: {
                display: 'none'
            }
        },

        tooltip: {
            enabled: false
        },

        legend: {
            enabled: false
        },

        zooming:{
            enabled: false
        },

        plotOptions: {
            pie: {
                size: 163,
                borderWidth: 0,
                dataLabels: {
                    enabled: false
                },
                innerSize: '80%',
            },
        },

        series: [{

            colorByPoint: true,
            enableMouseTracking: false,
            showInLegend: false,
            data: [ {
                name: 'Активы' ,
                y: 17000,

            }, {
                name: 'Команда',
                y: 15000,

            }, {
                name: 'Промо',
                y: 28000,

            }]
        }]
    });

});




